<template>
  <section id="PlanIndexSection">
    <vue-headful title="FLPN Aviasolutions - List Plans"></vue-headful>
    <div class="columns">
      <div class="column is-4 is-full-mobile fixedleft no-print" style="position: sticky;">
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">
              Flight Plans
            </p>
          </header>
          <div class="card-content plan_selector">
            <div class="columns">
              <div class="column is-6">
                <b-field label="Date Range">
                  <b-field>
                    <datepicker placeholder="Select date range" ref="datepicker" style="min-width: 175px"
                                :config="{ dateFormat: 'm/d/Y', static: true, mode: 'range' }"
                                v-model="filter.dateRange"></datepicker>
                    <a class="button" data-clear @click="clearDateRange"><i class="fa fa-times"></i></a>
                  </b-field>
                </b-field>
              </div>
              <div class="column is-3">
                <PaginationResults v-model="perPage"/>
              </div>
              <div class="column is-3">
                <label class="label">&nbsp;</label>
                <button type="button" class="button is-link is-fullwidth" @click="onFilter()">
                                <span class="icon">
                                <i class="fas fa-filter"></i>
                                </span>
                  <span class="has-text-weight-bold is-uppercase">Filter</span>
                </button>
              </div>
            </div>
            <div>
              <b-table
                  paginated
                  backend-pagination
                  backend-sorting
                  :data="pagination.records"
                  :loading="pagination.loading"
                  :total="pagination.total"
                  :per-page="pagination.perPage"
                  :default-sort-direction="pagination.defaultSortOrder"
                  :default-sort="[pagination.sortField, pagination.sortOrder]"
                  :row-class="(row, index) => row.is_selected === true && 'is-info'"
                  @click="onShow"
                  @page-change="onPageChange"
                  @sort="onSort"
                  :sticky-header="true"
                  :height="listHeight"
                  :selected.sync="selected"
              >
                <template slot-scope="props">
                  <b-table-column field="created_at" label="Generated At" sortable>
                    <span @click="onShow(props.row)">{{ props.row.generated_at }}</span>
                  </b-table-column>
                  <b-table-column field="flight_number" label="Flight Number">
                    <span @click="onShow(props.row)">{{ props.row.flight ? props.row.flight.flight_number : '' }}</span>
                  </b-table-column>
                  <b-table-column label="Dep Dest">
                    <span @click="onShow(props.row)">{{
                        props.row.flight ? props.row.flight.route.departure_icao : ''
                      }}</span><br>
                    <span
                        @click="onShow(props.row)">{{
                        props.row.flight ? props.row.flight.route.destination_icao : ''
                      }}</span>
                  </b-table-column>
                  <b-table-column label="...">
                    <div class="field has-addons">
                      <p class="control">
                        <b-tooltip :label="props.row.user.full_name+' ('+props.row.user_ip_address+')'" type="is-dark">
                          <b-icon pack="far" icon="user"></b-icon>
                        </b-tooltip>
                      </p>
                    </div>
                  </b-table-column>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-8 is-full-mobile absoluteright" id="plan_report_panel">
        <div class="card" id="flight" v-if="flightPlan.plan && flightPlan.plan.id">
          <header class="card-header hideonprint">
            <p class="card-header-title">
              Plan Report {{flightPlan.plan.id}}
            </p>
          </header>
          <div class="card-content plan_report">
            <div ref="report">
              <PlanReport :flightPlan="flightPlan" :result="flightPlan.plan" class="showonprint"></PlanReport>
            </div>
          </div>
        </div>
        <div class="columns is-centered hideonprint" v-if="!flightPlan.plan || !flightPlan.plan.id">
          <div class="column is-12 has-text-centered" style="margin-top:20%">
            <span>Select a Flight Plan on the left to see the Plan Report</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import PlansService from "../../services/plans.service.js";
import PlanReport from "./PlanReport.vue";
import Datepicker from 'vue-bulma-datepicker'
import PlanPaginationFilter from "../../filters/plan-pagination.filter";
import PaginationResults from "@/components/shared/PaginationResults";

export default {
  name: "PlansIndex",
  data() {
    return {
      listHeight: window.innerHeight * 0.78,
      selected: null,
      plansService: new PlansService(),
      selectedPlan: {},
      dateRange: null,
      flightPlan: {plan: null},
      perPage: 20,
      pagination: {
        records: [],
        total: 0,
        loading: false,
        sortField: "created_at",
        sortOrder: "desc",
        defaultSortOrder: "desc",
        page: 1,
        perPage: 20
      },
      filter: new PlanPaginationFilter()
    };
  },
  methods: {
    onFilter() {
      this.paginate();
    },
    paginate() {
      const params = {
        sort_field: this.pagination.sortField,
        sort_order: this.pagination.sortOrder,
        per_page: this.perPage,
        page: this.pagination.page
      };
      if (!this.filter.isEmpty()) {
        params.filter = this.generateFilters();
      }
      this.pagination.loading = true;
      this.plansService.paginate(params).then(
          ({data}) => {
            this.pagination.perPage = this.perPage
            this.pagination.records = data.data;
            this.pagination.total = data.meta.total;
            this.pagination.loading = false
          },
          () => {
            this.pagination.records = []
            this.pagination.total = 0
            this.pagination.loading = false
            this.$buefy.toast.open({
              message: 'An error ocurred while paginating!!!',
              position: 'is-bottom',
              type: 'is-danger'
            });
          }
      );
    },
    onShow(plan) {
      this.selectedPlan.is_selected = false;
      this.selectedPlan = plan;
      plan.is_selected = true;
      this.selectedPlan.is_selected = true;
      const loadingComponent = this.$buefy.loading.open({container: null});
      this.flightPlan = {};
      this.$forceUpdate();
      this.plansService.edit(plan.id).then(({data}) => {
            loadingComponent.close();
            this.flightPlan.plan = data.data;
            this.$forceUpdate();
          },
          () => {
            loadingComponent.close();
            this.$buefy.toast.open({
              message: 'An error occurred while trying to load a flight plan.',
              type: 'is-danger'
            });
          });
    },
    /*
    * Handle page-change event
    */
    onPageChange(page) {
      this.pagination.page = page;
      this.paginate();
    },
    /*
    * Handle sort event
    */
    onSort(field, order) {
      this.pagination.sortField = field;
      this.pagination.sortOrder = order;
      this.paginate();
    },
    onRowClick() {
      // alert('lel');
    },
    generateFilters() {
      var filtro = {};
      if (this.filter.hasValue(this.filter.dateRange)) {
        filtro.dateRange = this.filter.dateRange;
      }
      return filtro;
    },
    clearDateRange() {
      this.filter.dateRange = null
      this.$emit('input', null)
      this.$nextTick(() => {
        this.$refs['datepicker'].datepicker.clear()
        this.$refs['datepicker'].date = null
        this.filter.dateRange = null
      })
    },
    setComponentMaxHeight() {
      const windowHeight = window.innerHeight - 20;
      this.listHeight = windowHeight * 0.78;
    }
  },
  mounted() {
    this.paginate();
    this.setComponentMaxHeight();
    window.addEventListener('resize', this.setComponentMaxHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setComponentMaxHeight);
  },
  components: {
    PaginationResults,
    PlanReport,
    Datepicker
  }
};
</script>
<style scoped>

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#PlanIndexSection .card-header {
  background-color: lightgrey;
}

.card-content.plan_selector {
  height: 92vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.card-content.plan_report {
  height: 92vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

@media print {
  .no-print, .no-print * {
    display: none !important;
  }

  .hideonprint {
    visibility: hidden;
  }

  .showonprint {
    visibility: visible !important;
  }
  .card-content.plan_report {
    height: 100%;
  }
  #plan_report_panel {
    width: auto;
    height: auto;
    padding: 0;
    .card {
      box-shadow: none;
    }
  }
  .tag:not(body) {
    padding: 0;
    border-radius: unset;
    background-color: white;
    justify-content: left;
  }
}

@media only screen and (min-width: 1100px) {
  /* For desktop: */
  .fixedleft {
    position: fixed;
    left: 10px;
  }

  .absoluteright {
    position: absolute;
    right: 0px;
  }
}


</style>