<template>
  <b-field :label="label">
    <b-select v-model="perPage" @input="change">
      <option v-for="option in options" :value="option"
              :key="option">{{ option }} per page
      </option>
    </b-select>
  </b-field>
</template>

<script>
export default {
  name: "pagination-results",

  props: {
    perPage: {
      type: Number,
      default: 20
    },
    options: {
      type: Array,
      default: () => [20, 50, 100, 200]
    },
    label: {
      type: String,
      default: "Results"
    }
  },
  methods: {
    change: function () {
      this.$emit('input', this.perPage)
    }
  }
}
</script>